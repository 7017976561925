import axios from 'axios'
import _ from 'lodash'
import products from './products.json'

const URL = process.env.REACT_APP_API_URL
const resellerId = process.env.REACT_APP_RESELLER_ID

export const itScopeIDs = {}

export const getPriceData = async values => {
  const chunks = _.chunk(products, 10)

  let response = {}

  for (const chunk of chunks) {
    const res = await axios.get(`${URL}/it_scope_price/${resellerId}`, {
      params: {
        ...values,
        sku: chunk.map(p => p.sku).join(','),
      },
    })

    response = { ...response, ...res.data }
  }

  return response
}
