import numeral from 'numeral'

numeral.register('locale', 'de', {
  delimiters: {
    thousands: ' ',
    decimal: ',',
  },
  abbreviations: {
    thousand: 'k',
    million: 'm',
    billion: 'b',
    trillion: 't',
  },
  ordinal(number) {
    return 'ter'
  },
  currency: {
    symbol: '€',
  },
})

numeral.locale('de')

export default numeral
