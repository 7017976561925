import React from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { withStyles } from '@material-ui/core/styles'
import products from './lib/products.json'
import numeral from './lib/numeral'
import _ from 'lodash'

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
    overflowX: 'auto',
  },
  table: {
    minWidth: 700,
  },
})

const Response = ({ classes, response }) => {
  return (
    <Table className={classes.table}>
      <TableHead>
        <TableRow>
          <TableCell>SKU</TableCell>
          <TableCell numeric>Verfügbarkeit</TableCell>
          <TableCell numeric>Durchschnittlicher Preis</TableCell>
          <TableCell>Name</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {products.map(({ sku, name }) => {
          const responseObject = Object.values(response).find(
            res => res.manufacturerSKU === sku,
          )

          if (!responseObject) {
            return (
              <TableRow key={sku}>
                <TableCell>{sku}</TableCell>
                <TableCell numeric>0</TableCell>
                <TableCell numeric>keine Angabe</TableCell>
                <TableCell component="th" scope="row">
                  {name}
                </TableCell>
              </TableRow>
            )
          }

          const { prices } = responseObject

          const inStock = prices.reduce(
            (acc, item) => acc + Number(item.stock),
            0,
          )

          const totalPrice = _.sumBy(
            prices,
            ({ price, stock }) =>
              (price / 1.02) * _.max([_.min([Number(stock), 100]), 1]),
          )
          const totalStock = _.sumBy(prices, ({ stock }) =>
            _.max([_.min([Number(stock), 100]), 1]),
          )

          const averagePrice = (totalPrice / totalStock) * 1.1

          return (
            <TableRow key={sku}>
              <TableCell>{sku}</TableCell>
              <TableCell numeric>{inStock}</TableCell>
              <TableCell numeric>
                {numeral(parseFloat(averagePrice)).format('0[.]00 $')}
              </TableCell>

              <TableCell component="th" scope="row">
                {name}
              </TableCell>
            </TableRow>
          )
        })}
      </TableBody>
    </Table>
  )
}

export default withStyles(styles)(Response)
