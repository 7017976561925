import React from 'react'
import { TextField, Button } from '@material-ui/core'
import { Form, Field } from 'react-final-form'
import { getPriceData } from './lib/fetch'
import Response from './Response'
import Paper from '@material-ui/core/Paper'
import { withStyles } from '@material-ui/core/styles'
import styled from 'styled-components'
import { useLocalStorage } from 'react-use'
import { DateTime } from 'luxon'

const Container = styled.div`
  display: flex;
  margin: 0 -0.5rem;
  & > * {
    margin: 0 0.5rem;
  }
`

const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
  },
})

const PriceForm = ({ classes }) => {
  const [lastUpdate, setLastUpdate] = useLocalStorage('last-update', null)
  const [response, setResponse] = useLocalStorage('response', null)

  const handleSubmit = async values => {
    localStorage.setItem('password', values.password)
    const res = await getPriceData(values)
    setResponse(res)
    setLastUpdate(DateTime.local().toISO())
  }
  return (
    <Paper className={classes.root} elevation={0}>
      Zuletzt aktualisiert:{' '}
      {DateTime.fromISO(lastUpdate).toLocaleString(DateTime.DATETIME_SHORT)}
      <Form
        initialValues={{
          password: localStorage.getItem('password'),
        }}
        onSubmit={handleSubmit}
        render={({ handleSubmit, submitting }) => (
          <form onSubmit={handleSubmit}>
            <Container>
              <Field
                name="password"
                render={({ input, meta }) => (
                  <div>
                    <TextField
                      // type="password"
                      label="Passwort"
                      {...input}
                      margin="normal"
                      autocomplete="off"
                    />
                  </div>
                )}
              />
            </Container>
            <div>
              <Button variant="contained" color="primary" type="submit">
                {submitting ? 'Bitte warten' : 'aktualisieren'}
              </Button>
            </div>
          </form>
        )}
      />
      {response && <Response response={response} />}
    </Paper>
  )
}

export default withStyles(styles)(PriceForm)
